import * as React from "react";
import { EGDSIcon } from "@egds/react-core/icons";
import { CollapsableContainerProps } from "./typings";
import { useLocalization } from "@shared-ui/localization-context";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "../../../utility/analytics/FlexAnalyticsUtils";
import { AnalyticsStore } from "bernie-plugin-mobx";
import { withStores } from "../../../../stores";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutFlexItem, EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSText } from "@egds/react-core/text";

const getTrackingConfig = (isExpanded: boolean, moduleName: string): FlexTrackingInfo => {
  const key = isExpanded ? "open" : "close";

  return {
    moduleName,
    action: Action.CLICK,
    linkName: `${key} collapsed wizard`,
    rfrr: `collapsed-wizard.${key}`,
  };
};

export enum CollapsableComponentName {
  STOREFRONT_WIZARD_REGION = "StorefrontWizardRegion",
}

export const CollapsableContainer = withStores("analytics")((props: CollapsableContainerProps) => {
  const useExpando = (defaultState: boolean, componentName: CollapsableComponentName, analytics?: AnalyticsStore) => {
    const [isExpandedWizard, setIsExpandedWizard] = React.useState(defaultState);

    const toggleCallback = React.useCallback(
      () =>
        setIsExpandedWizard((isExpandedState) => {
          const nextState = !isExpandedState;
          const trackInfo = getTrackingConfig(nextState, componentName);

          sendDelayedTrackEvent(trackInfo, analytics);

          return nextState;
        }),
      [setIsExpandedWizard, analytics]
    );

    return {
      toggle: toggleCallback,
      isExpanded: isExpandedWizard,
    };
  };

  const { toggle, isExpanded } = useExpando(false, props.componentName, props.analytics);

  const { formatText } = useLocalization();

  if (!props.isCollapsedWizardEnabled) {
    return <>{props.children}</>;
  }

  return (
    <div className="CollapsedWizard collapsed-wizard" data-testid="collapsed-wizard">
      <EGDSSpacing padding={{ block: "three" }}>
        <div onClick={toggle} className="uitk-link" data-testid="collapsed-wizard-toggle">
          <EGDSLayoutFlex>
            <EGDSLayoutFlexItem>
              <EGDSSpacing margin="three">
                <EGDSIcon id="playback-icon" name="search" />
              </EGDSSpacing>
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlex wrap="wrap">
              <EGDSLayoutFlexItem minWidth="full_width">
                <div data-testid="collapsed-wizard-title">
                  <EGDSText size={500} weight="medium" overflow="truncate">
                    {formatText("wizard.collapsed.title")}
                  </EGDSText>
                </div>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem>
                <div data-testid="collapsed-wizard-subtitle">
                  <EGDSText size={200} weight="regular" overflow="truncate">
                    {formatText("wizard.collapsed.subtitle")}
                  </EGDSText>
                </div>
              </EGDSLayoutFlexItem>
            </EGDSLayoutFlex>
          </EGDSLayoutFlex>
        </div>
      </EGDSSpacing>
      {isExpanded && <>{props.children}</>}
    </div>
  );
});
